define("discourse/plugins/ht-forum-plugin/discourse/components/footer", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Footer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "footer", [_tracking.tracked], function () {
      return {};
    }))();
    #footer = (() => (dt7948.i(this, "footer"), void 0))();
    constructor(owner, args) {
      super(owner, args);
      const path = "https://api.hearingtracker.com/api/v1/settings/fetch/footer";
      fetch(path).then(response => {
        response.json().then(result => {
          this.footer = JSON.parse(result.value);
        });
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div id="#ht-footer" class="ht-footer">
          <div>
            <div class="left">
              <div class="captions">
                <div class="caption1">{{this.footer.caption1}}</div>
                <div class="caption2">{{this.footer.caption2}}</div>
              </div>
              <div class="logo">
                <a href="https://www.hearingtracker.com">
                  <img src="https://www.hearingtracker.com/images/logo-white.svg" />
                </a>
              </div>
            </div>
            <div class="columns">
              {{#each this.footer.columns as |col|}}
                <div>
                  <div class="title">{{col.title}}</div>
                  <div class="links">
                    {{#each col.links as |link|}}
                      <div class="link">
                        <a href={{link.url}}>{{link.text}}</a>
                      </div>
                    {{/each}}
                  </div>
                </div>
              {{/each}}
            </div>
          </div>
        </div>
      
    */
    {
      "id": "/vAvrrp6",
      "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"#ht-footer\"],[14,0,\"ht-footer\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"left\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"captions\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"caption1\"],[12],[1,[30,0,[\"footer\",\"caption1\"]]],[13],[1,\"\\n            \"],[10,0],[14,0,\"caption2\"],[12],[1,[30,0,[\"footer\",\"caption2\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"logo\"],[12],[1,\"\\n            \"],[10,3],[14,6,\"https://www.hearingtracker.com\"],[12],[1,\"\\n              \"],[10,\"img\"],[14,\"src\",\"https://www.hearingtracker.com/images/logo-white.svg\"],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"columns\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"footer\",\"columns\"]]],null]],null],null,[[[1,\"            \"],[10,0],[12],[1,\"\\n              \"],[10,0],[14,0,\"title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n              \"],[10,0],[14,0,\"links\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"links\"]]],null]],null],null,[[[1,\"                  \"],[10,0],[14,0,\"link\"],[12],[1,\"\\n                    \"],[10,3],[15,6,[30,2,[\"url\"]]],[12],[1,[30,2,[\"text\"]]],[13],[1,\"\\n                  \"],[13],[1,\"\\n\"]],[2]],null],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"col\",\"link\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/components/footer.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Footer;
});
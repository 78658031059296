define("discourse/plugins/ht-forum-plugin/discourse/components/audiogram-link", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/plugins/ht-forum-plugin/discourse/components/audiogram-modal", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _audiogramModal, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AudiogramLink extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "open", [_tracking.tracked], function () {
      return false;
    }))();
    #open = (() => (dt7948.i(this, "open"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "audiogram", [_tracking.tracked]))();
    #audiogram = (() => (dt7948.i(this, "audiogram"), void 0))();
    constructor(owner, args) {
      super(owner, args);
      try {
        this.audiogram = JSON.parse(args.audiogram);
      } catch (ex) {}
      if (!this.validateAudiogram(this.audiogram)) {
        this.audiogram = null;
      }
      // eslint-disable-next-line no-console
      console.debug('%o', {
        user: args.user,
        current: this.currentUser,
        audiogram: this.audiogram
      });
    }
    validateAudiogram(audiogram) {
      if (!audiogram) {
        return false;
      }
      if (!audiogram.threshold) {
        return false;
      }
      return true;
    }
    handleOpen() {
      this.open = true;
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleOpen", [_object.action]))();
    handleClose() {
      this.open = false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleClose", [_object.action]))();
    updateAudiogram(newAudiogram) {
      // Hack so a user's own audiogram refreshes
      // Seems like there should be a way to reload the currentUser custom
      // fields, but I can't figure it out
      this.audiogram = newAudiogram;
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateAudiogram", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.audiogram}}
          <div class="ht-audiogram-link">
            <DButton
              @translatedLabel="Audiogram"
              @action={{this.handleOpen}}
              @class="btn-link"
            />
          </div>
    
          {{#if this.open}}
            <AudiogramModal
              @user={{@user}}
              @audiogram={{this.audiogram}}
              @closeModal={{this.handleClose}}
              @updateAudiogram={{this.updateAudiogram}}
            />
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "O+fJBklO",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"audiogram\"]],[[[1,\"      \"],[10,0],[14,0,\"ht-audiogram-link\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@translatedLabel\",\"@action\",\"@class\"],[\"Audiogram\",[30,0,[\"handleOpen\"]],\"btn-link\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"open\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@user\",\"@audiogram\",\"@closeModal\",\"@updateAudiogram\"],[[30,1],[30,0,[\"audiogram\"]],[30,0,[\"handleClose\"]],[30,0,[\"updateAudiogram\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[\"@user\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/components/audiogram-link.js",
      "scope": () => [_dButton.default, _audiogramModal.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AudiogramLink;
});
define("discourse/plugins/ht-forum-plugin/discourse/components/audiogram-chart", ["exports", "@glimmer/component", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/load-script", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dButton, _dModal, _loadScript, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AudiogramChart extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      this.audiogram = args.audiogram;
      this.user = args.user;
      // eslint-disable-next-line no-console
      console.log("chart: %o", this.args.audiogram);
      this.containerId = `audiogram-${this.user.id}`;
      (0, _loadScript.default)("https://code.highcharts.com/4.2.3/highcharts.js").then(() => {
        (0, _loadScript.default)("https://code.highcharts.com/4.2.3/highcharts-more.js").then(() => {
          this.buildAudiogram(window.Highcharts, this.audiogram, this.containerId);
        });
      });
    }
    getHearingRange(severity) {
      let minThreshold, maxThreshold;
      switch (severity) {
        case "normal":
          minThreshold = -10;
          maxThreshold = 15;
          break;
        case "slight":
          minThreshold = 16;
          maxThreshold = 25;
          break;
        case "mild":
          minThreshold = 26;
          maxThreshold = 40;
          break;
        case "moderate":
          minThreshold = 41;
          maxThreshold = 55;
          break;
        case "moderately-severe":
          minThreshold = 56;
          maxThreshold = 70;
          break;
        case "severe":
          minThreshold = 71;
          maxThreshold = 90;
          break;
        case "profound":
          minThreshold = 91;
          maxThreshold = 120;
          break;
      }
      const a = [];
      [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].forEach(function (x) {
        a.push([x, minThreshold, maxThreshold]);
      });
      return a;
    }
    buildAudiogram(Highcharts, audiogram, containerId) {
      const thresholds = [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
      const left = audiogram.threshold.map((t, idx) => [thresholds[idx], Number(t.left || 0)]);
      const right = audiogram.threshold.map((t, idx) => [thresholds[idx], Number(t.right || 0)]);
      Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
        return ["M", x, y, "L", x + w, y + h, "M", x + w, y, "L", x, y + h, "z"];
      };
      if (Highcharts.VMLRenderer) {
        Highcharts.VMLRenderer.prototype.symbols.cross = Highcharts.SVGRenderer.prototype.symbols.cross;
      }
      const tones = ["250Hz", "500Hz", "1000Hz", "2000Hz", "4000Hz", "8000Hz"];
      const chart = Highcharts.chart(containerId, {
        credits: false,
        title: {
          align: "center",
          text: "",
          style: {
            fontSize: "14px",
            fontFamily: "source_sans_probold, sans-serif"
          }
        },
        xAxis: [{
          tickmarkPlacement: "on",
          gridLineColor: "#ddd",
          gridLineWidth: 1,
          opposite: true,
          categories: tones,
          title: {
            text: null
          },
          min: 0,
          max: 5
        }, {
          linkedTo: 0,
          tickmarkPlacement: "on",
          gridLineColor: "#eee",
          gridLineWidth: 1,
          tickPositions: [1.5, 2.5, 3.5, 4.5],
          labels: {
            formatter: function () {
              let v = this.value;
              if (v === 1.5) {
                return "750Hz";
              }
              if (v === 2.5) {
                return "1.5kHz";
              }
              if (v === 3.5) {
                return "3kHz";
              }
              if (v === 4.5) {
                return "6kHz";
              }
            }
          }
        }],
        yAxis: {
          allowDecimals: false,
          reversed: true,
          title: {
            text: null
          },
          max: 120,
          min: -10,
          tickInterval: 10
        },
        tooltip: {
          crosshairs: true,
          valueSuffix: "dB",
          formatter: function () {
            let v = this.x;
            if (v === 1.5) {
              v = "750Hz";
            } else if (v === 2.5) {
              v = "1500Hz";
            } else if (v === 3.5) {
              v = "3000Hz";
            } else if (v === 4.5) {
              v = "6000Hz";
            }
            if (this.point.color === "red" || this.point.color === "blue") {
              return this.series.name + ":<br /><b>" + this.y + "dB </b> at <b>" + v + "</b>";
            } else {
              return this.series.name;
            }
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            connectNulls: true
          }
        },
        series: [{
          name: "Right Ear Hearing Level",
          data: right,
          zIndex: 1,
          color: "red",
          marker: {
            fillColor: "white",
            lineWidth: 2,
            lineColor: "red"
          }
        }, {
          name: "Left Ear Hearing Level",
          data: left,
          zIndex: 1,
          color: "blue",
          marker: {
            symbol: "cross",
            fillColor: "white",
            lineWidth: 2,
            lineColor: "blue"
          }
        }, {
          type: "arearange",
          name: "Normal hearing",
          data: this.getHearingRange('normal'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#7ebefa",
          fillOpacity: 0.3,
          zIndex: 0
        }, {
          type: "arearange",
          name: "Slight hearing loss range",
          data: this.getHearingRange('slight'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#91a2d5",
          fillOpacity: 0.3,
          zIndex: 0
        }, {
          type: "arearange",
          name: "Mild hearing loss range",
          data: this.getHearingRange('mild'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#a77ea4",
          fillOpacity: 0.3,
          zIndex: 0
        }, {
          type: "arearange",
          name: "Moderate hearing loss range",
          data: this.getHearingRange('moderate'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#ba5f7d",
          fillOpacity: 0.3,
          zIndex: 0
        }, {
          type: "arearange",
          name: "Moderately-severe hearing loss range",
          data: this.getHearingRange('moderately-severe'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#cc4358",
          fillOpacity: 0.3,
          zIndex: 0
        }, {
          type: "arearange",
          name: "Severe hearing loss range",
          data: this.getHearingRange('severe'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#dd2733",
          fillOpacity: 0.3,
          zIndex: 0
        }, {
          type: "arearange",
          name: "Profound hearing loss range",
          data: this.getHearingRange('profound'),
          lineWidth: 0,
          linkedTo: ":previous",
          color: "#f40203",
          fillOpacity: 0.3,
          zIndex: 0
        }]
      });
      return chart;
      /*
      //var tones = [250,500,1000,2000,4000,8000];
      var ticks = [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
       var freqMap = {
        0: "250 Hz",
        1: "500 Hz",
        1.5: "750 Hz",
        2: "1000 Hz",
        2.5: "1500 Hz",
        3: "2000 Hz",
        3.5: "3000 Hz",
        4: "4000 Hz",
        4.5: "6000 Hz",
        5: "8000 Hz",
      };
        function closest(array, num) {
        var i = 0;
        var minDiff = 1000;
        var ans;
        for (i in array) {
          var m = Math.abs(num - array[i]);
          if (m < minDiff) {
            minDiff = m;
            ans = array[i];
          }
        }
        return ans;
      }
       $("#container").bind("mousemove", function (e) {
        var chart = $(this).highcharts();
        e = chart.pointer.normalize(e);
        var correctedY = Math.ceil(chart.yAxis[0].toValue(e.chartY) / 5) * 5;
        $("#y-value").text(correctedY);
      });
      */ /*
          Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
            return ["M", x, y, "L", x + w, y + h, "M", x + w, y, "L", x, y + h, "z"];
          };
          if (Highcharts.VMLRenderer) {
            Highcharts.VMLRenderer.prototype.symbols.cross =
              Highcharts.SVGRenderer.prototype.symbols.cross;
          }
          let tones = ["250Hz", "500Hz", "1000Hz", "2000Hz", "4000Hz", "8000Hz"];
          let chart = Highcharts.chart(containerId, {
            credits: false,
            title: false,
            xAxis: [
              {
                tickmarkPlacement: "on",
                gridLineColor: "#ddd",
                gridLineWidth: 1,
                opposite: true,
                categories: tones,
                title: {
                  text: "Freq (Hz)",
                },
                min: 0,
                max: 5,
              },
              {
                linkedTo: 0,
                tickmarkPlacement: "on",
                gridLineColor: "#eee",
                gridLineWidth: 1,
                //tickPositions:[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                tickPositions: [1.5, 2.5, 3.5, 4.5],
                labels: {
                  formatter: function () {
                    let v = this.value;
                    if (v === 1.5) {
                      return "750Hz";
                    }
                    if (v === 2.5) {
                      return "1500Hz";
                    }
                    if (v === 3.5) {
                      return "3000Hz";
                    }
                    if (v === 4.5) {
                      return "6000Hz";
                    }
                  },
                },
              },
            ],
             yAxis: {
              allowDecimals: false,
              reversed: true,
              title: {
                text: "Decibels (dB)",
              },
              max: 120,
              min: -10,
              tickInterval: 10,
            },
             tooltip: {
              crosshairs: true,
              valueSuffix: "dB",
              formatter: function () {
                let v = this.x;
                if (v === 1.5) {
                  v = "750Hz";
                } else if (v === 2.5) {
                  v = "1500Hz";
                } else if (v === 3.5) {
                  v = "3000Hz";
                } else if (v === 4.5) {
                  v = "6000Hz";
                }
                if (this.point.color === "red" || this.point.color === "blue") {
                  return (
                    this.series.name +
                    ":<br /><b>" +
                    this.y +
                    "dB </b> at <b>" +
                    v +
                    "</b>"
                  );
                } else {
                  return this.series.name;
                }
              },
            },
             legend: {
              enabled: false,
            },
            plotOptions: {
              series: {
                connectNulls: true,
              },
            },
            series: [
              {
                name: "Right Ear Hearing Level",
                data: right,
                zIndex: 1,
                color: "red",
                marker: {
                  fillColor: "white",
                  lineWidth: 2,
                  lineColor: "red",
                },
              },
              {
                name: "Left Ear Hearing Level",
                data: left,
                zIndex: 1,
                color: "blue",
                marker: {
                  symbol: "cross",
                  fillColor: "white",
                  lineWidth: 2,
                  lineColor: "blue",
                },
              },
               {
                type: "arearange",
                name: "Normal hearing",
                data: this.getHearingRange("normal"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#7ebefa",
                fillOpacity: 0.3,
                zIndex: 0,
              },
              {
                type: "arearange",
                name: "Slight hearing loss range",
                data: this.getHearingRange("slight"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#91a2d5",
                fillOpacity: 0.3,
                zIndex: 0,
              },
              {
                type: "arearange",
                name: "Mild hearing loss range",
                data: this.getHearingRange("mild"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#a77ea4",
                fillOpacity: 0.3,
                zIndex: 0,
              },
              {
                type: "arearange",
                name: "Moderate hearing loss range",
                data: this.getHearingRange("moderate"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#ba5f7d",
                fillOpacity: 0.3,
                zIndex: 0,
              },
              {
                type: "arearange",
                name: "Moderately-severe hearing loss range",
                data: this.getHearingRange("moderately-severe"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#cc4358",
                fillOpacity: 0.3,
                zIndex: 0,
              },
              {
                type: "arearange",
                name: "Severe hearing loss range",
                data: this.getHearingRange("severe"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#dd2733",
                fillOpacity: 0.3,
                zIndex: 0,
              },
              {
                type: "arearange",
                name: "Profound hearing loss range",
                data: this.getHearingRange("profound"),
                lineWidth: 0,
                linkedTo: ":previous",
                color: "#f40203",
                fillOpacity: 0.3,
                zIndex: 0,
              },
            ],
          });
            */
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal @title="Audiogram: {{@user.username}}" @closeModal={{@closeModal}}>
          <:body>
            <div id="{{this.containerId}}" class="audiogram-chart"></div>
          </:body>
          <:footer>
            {{#if @editable}}
              <DButton @action={{@edit}} @translatedLabel="Edit" class="btn-info" />
            {{/if}}
            <DButton
              @action={{@closeModal}}
              @translatedLabel="Close"
              class="btn-flat d-modal-cancel"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "Rs5E8/JZ",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[29,[\"Audiogram: \",[30,1,[\"username\"]]]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[15,1,[29,[[30,0,[\"containerId\"]]]]],[14,0,\"audiogram-chart\"],[12],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n\"],[41,[30,3],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-info\"]],[[\"@action\",\"@translatedLabel\"],[[30,4],\"Edit\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[32,1],[[24,0,\"btn-flat d-modal-cancel\"]],[[\"@action\",\"@translatedLabel\"],[[30,2],\"Close\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@user\",\"@closeModal\",\"@editable\",\"@edit\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/components/audiogram-chart.js",
      "scope": () => [_dModal.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AudiogramChart;
});
define("discourse/plugins/ht-forum-plugin/templates/connectors/user-profile-primary/show-user-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Location />
  
  */
  {
    "id": "ux83EUIJ",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"]],[],false,[\"location\"]]",
    "moduleName": "discourse/plugins/ht-forum-plugin/templates/connectors/user-profile-primary/show-user-card.hbs",
    "isStrictMode": false
  });
});
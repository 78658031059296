define("discourse/plugins/ht-forum-plugin/discourse/initializers/extend-for-audiograms", ["exports", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _pluginApi, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //attach audiogram link to user posts
  function attachAudiogramLink(api) {
    api.decorateWidget('poster-name:after', dec => {
      console.log('da: %o', dec.attrs);
      const location = dec.getModel().get('user_location');
      if (!location || location === 'none') {
        return;
      }
      return dec.h('span', {
        className: "nationalflag-post-location"
      }, location);
    });
    (0, _renderGlimmer.registerWidgetShim)('ht-audiogram-widget', 'div.ht-audiogram-connector', (0, _templateFactory.createTemplateFactory)(
    /*
      <AudiogramLink @user={{@data.user}} @audiogram={{@data.audiogram}} />
    */
    {
      "id": "noVHBeCa",
      "block": "[[[8,[39,0],null,[[\"@user\",\"@audiogram\"],[[30,1,[\"user\"]],[30,1,[\"audiogram\"]]]],null]],[\"@data\"],false,[\"audiogram-link\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/initializers/extend-for-audiograms.js",
      "isStrictMode": false
    }));
    api.decorateWidget('poster-name:after', helper => {
      const audiogram = helper.attrs?.userCustomFields?.audiogram;
      if (audiogram) {
        const user = helper.attrs.user;
        return helper.attach('ht-audiogram-widget', {
          user,
          audiogram
        });
      }
    });
  }
  var _default = _exports.default = {
    name: 'extend-for-audiograms',
    initialize(container) {
      const siteSettings = container.lookup('service:site-settings');
      if (siteSettings.audiograms_enabled) {
        (0, _pluginApi.withPluginApi)('0.1', api => attachAudiogramLink(api));
      }
    }
  };
});
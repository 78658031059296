define("discourse/plugins/ht-forum-plugin/discourse/components/audiogram-preferences", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/plugins/ht-forum-plugin/discourse/components/audiogram-modal", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _audiogramModal, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AudiogramPreferences extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "open", [_tracking.tracked], function () {
      return false;
    }))();
    #open = (() => (dt7948.i(this, "open"), void 0))();
    constructor(owner, args) {
      super(owner, args);
    }
    closeModal() {
      this.open = false;
    }
    static #_2 = (() => dt7948.n(this.prototype, "closeModal", [_object.action]))();
    openModal() {
      this.open = true;
    }
    static #_3 = (() => dt7948.n(this.prototype, "openModal", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @translatedLabel="My Audiogram"
          @action={{this.openModal}}
          @class="btn-ht-success"
        />
    
        {{#if this.open}}
          <AudiogramModal
            @user={{@user}}
            @closeModal={{this.closeModal}}
          />
        {{/if}}
      
    */
    {
      "id": "YoS/5Gh1",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@translatedLabel\",\"@action\",\"@class\"],[\"My Audiogram\",[30,0,[\"openModal\"]],\"btn-ht-success\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"open\"]],[[[1,\"      \"],[8,[32,1],null,[[\"@user\",\"@closeModal\"],[[30,1],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@user\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/components/audiogram-preferences.js",
      "scope": () => [_dButton.default, _audiogramModal.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AudiogramPreferences;
});
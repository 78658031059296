define("discourse/plugins/ht-forum-plugin/discourse/templates/connectors/below-footer/ht-below-footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "ihXMpkTh",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/ht-forum-plugin/discourse/templates/connectors/below-footer/ht-below-footer.hbs",
    "isStrictMode": false
  });
});
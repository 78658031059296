define("discourse/plugins/ht-forum-plugin/discourse/templates/connectors/above-site-header/ht-above-site-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Nav />
  
  */
  {
    "id": "q5JyBq0n",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"]],[],false,[\"nav\"]]",
    "moduleName": "discourse/plugins/ht-forum-plugin/discourse/templates/connectors/above-site-header/ht-above-site-header.hbs",
    "isStrictMode": false
  });
});
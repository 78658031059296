define("discourse/plugins/ht-forum-plugin/discourse/components/audiogram-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/ht-forum-plugin/discourse/components/audiogram-chart", "discourse/plugins/ht-forum-plugin/discourse/components/audiogram-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _audiogramChart, _audiogramForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AudiogramModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "state", [_tracking.tracked], function () {
      return {
        mode: "edit",
        audiogram: null,
        user: null
      };
    }))();
    #state = (() => (dt7948.i(this, "state"), void 0))();
    constructor(owner, args) {
      super(owner, args);
      const editable = args.user?.id === this.currentUser?.id;
      if (editable && !args.audiogram) {
        this.state = {
          mode: "edit",
          editable
        };
      } else {
        if (args.audiogram) {
          this.state = {
            mode: "view",
            editable,
            audiogram: args.audiogram
          };
        } else {
          const audiogram = JSON.parse(args.user.custom_fields.audiogram);
          this.state = {
            mode: "view",
            editable,
            audiogram
          };
        }
      }
    }
    get viewOpen() {
      return this.state.mode === "view";
    }
    get editOpen() {
      return this.state.mode === "edit";
    }
    handleView(audiogram) {
      this.state = {
        ...this.state,
        mode: "view",
        audiogram
      };
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleView", [_object.action]))();
    handleEdit() {
      this.state = {
        ...this.state,
        mode: "edit"
      };
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleEdit", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.viewOpen}}
          <AudiogramChart
            @audiogram={{this.state.audiogram}}
            @edit={{this.handleEdit}}
            @editable={{this.state.editable}}
            @closeModal={{@closeModal}}
            @user={{@user}}
          />
        {{/if}}
        {{#if this.editOpen}}
          <AudiogramForm
            @view={{this.handleView}}
            @closeModal={{@closeModal}}
            @updateAudiogram={{@updateAudiogram}}
          />
        {{/if}}
      
    */
    {
      "id": "GXGxdo4M",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"viewOpen\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@audiogram\",\"@edit\",\"@editable\",\"@closeModal\",\"@user\"],[[30,0,[\"state\",\"audiogram\"]],[30,0,[\"handleEdit\"]],[30,0,[\"state\",\"editable\"]],[30,1],[30,2]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"editOpen\"]],[[[1,\"      \"],[8,[32,1],null,[[\"@view\",\"@closeModal\",\"@updateAudiogram\"],[[30,0,[\"handleView\"]],[30,1],[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@closeModal\",\"@user\",\"@updateAudiogram\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/components/audiogram-modal.js",
      "scope": () => [_audiogramChart.default, _audiogramForm.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AudiogramModal;
});
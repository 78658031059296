define("discourse/plugins/ht-forum-plugin/discourse/components/location", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Location extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    constructor(owner, args) {
      super(owner, args);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.currentUser.custom_fields.nationalflag_iso}}
            {{#if this.currentUser.location}}
              <span class="nationalflag-location">{{this.currentUser.location}}</span>
            {{/if}}
        {{else}}
          {{#if this.currentUser.location}}
            <span class="nationalflag-location">{{this.currentUser.location}}</span>
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "LW4hFfsE",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"custom_fields\",\"nationalflag_iso\"]],[[[41,[30,0,[\"currentUser\",\"location\"]],[[[1,\"          \"],[10,1],[14,0,\"nationalflag-location\"],[12],[1,[30,0,[\"currentUser\",\"location\"]]],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"currentUser\",\"location\"]],[[[1,\"        \"],[10,1],[14,0,\"nationalflag-location\"],[12],[1,[30,0,[\"currentUser\",\"location\"]]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/ht-forum-plugin/discourse/components/location.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Location;
});